import React, { useState } from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM, USER_INTER } from '../constant';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { TextElem } from '../../../common/text';
import { SelectElem } from '../../../common/select';
import { FieldTextAreaElem } from '../../../common/field-text-area';
import { ButtonElem } from '../../../common/button';
import { DatePickerElem } from '../../../common/date-picker';
import { OPTION_ITEM_DATA } from '../../../common/select/constant';

export const Order: React.FC<{
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  setValue: Function;
  formik: FormikValues;
  isSubmitDisabled: Function;
  timeOption: OPTION_ITEM_DATA[];
  isFieldDisabled: Function;
  isTimeLoading: boolean;
  availableDate?: string[];
}> = ({
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  setValue,
  formik,
  isSubmitDisabled,
  timeOption,
  isFieldDisabled,
  isTimeLoading,
  availableDate,
}) => {
  return (
    <Container>
      <TextContainer>
        <TextElem
          type="medium"
          color="textPrimary"
          size="main"
          tid="ORDER.ITEM.REGISTRATION.TITLE_ORDER"
        />
        {/* <TextElem
          type="regular"
          color="textSecondary"
          size="input"
          tid="ORDER.ITEM.REGISTRATION.DESCRIPTION_ORDER"
          lineHeight="1.6em"
        /> */}
      </TextContainer>
      <FormContainer>
        <Double>
          {/* <SelectElem
            isDisabled={isFieldDisabled()}
            dynamic
            placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.DATE}
            onChange={setFieldValue}
            options={dateOption}
            title="ORDER.ITEM.REGISTRATION.DATE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
            error={isFieldError(FORM_VALUE_ENUM.DATE)}
            value={setValue(dateOption, FORM_VALUE_ENUM.DATE)}
          /> */}

          <DatePickerElem
            availableDateArr={availableDate}
            isDisabled={isFieldDisabled()}
            name={FORM_VALUE_ENUM.DATE}
            title="ORDER.ITEM.REGISTRATION.DATE"
            placeholder="ORDER.ITEM.REGISTRATION.DATE_PLACEHOLDER"
            handleChange={(e) => {
              if (e instanceof Date) {
                const formattedDate = e.toLocaleDateString('en-CA');
                formik.setFieldValue(FORM_VALUE_ENUM.DATE, formattedDate);
              } else {
                formik.setFieldValue(FORM_VALUE_ENUM.DATE, null);
              }
            }}
            value={getFieldValue(FORM_VALUE_ENUM.DATE)}
          />

          <SelectElem
            isDisabled={!getFieldValue(FORM_VALUE_ENUM.DATE)}
            dynamic
            isLoading={isTimeLoading}
            placeholder="ORDER.ITEM.REGISTRATION.TIME_PLACEHOLDER"
            name={FORM_VALUE_ENUM.TIME}
            onChange={setFieldValue}
            options={timeOption}
            title="ORDER.ITEM.REGISTRATION.TIME"
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
            value={setValue(timeOption, FORM_VALUE_ENUM.TIME)}
          />
        </Double>
        <FieldTextAreaElemStyled
          disabled={isFieldDisabled()}
          name={FORM_VALUE_ENUM.COMMENT}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
          title="ORDER.ITEM.REGISTRATION.COMMENT"
          placeholder="ORDER.ITEM.REGISTRATION.COMMENT_PLACEHOLDER"
          errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
          error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
        />
      </FormContainer>
      <ButtonElem
        disabled={isSubmitDisabled()}
        type="submit"
        onClick={formik.handleSubmit}
        tid="ORDER.ITEM.CONFIRM"
      />
    </Container>
  );
};

const FieldTextAreaElemStyled = styled(FieldTextAreaElem)`
  @media screen and (max-width: 760px) {
    textarea {
      height: 52px;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
`;

const Double = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(2)};

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  width: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.PROFILE_PHOTO
  ]}px;
  display: flex;
  flex-direction: column;
  gap: ${Spacing(6)};
  padding: ${Spacing(6)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
`;

const TextContainer = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  flex-direction: column;
`;
