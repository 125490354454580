import React from 'react';
import styled, { css } from 'styled-components';
import { TextElem } from '../../common/text';
import { CardElem } from '../../common/card';
import { GridElem } from '../../common/grid';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { useHistory } from 'react-router';
import { CATALOG_ITEM_LIST, TITLE } from './constant';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';
// import backgroundImage from '../../asset/background.jpg';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { KNOWLEDGE_LANDING_ID_ENUM } from '../landing-depression-hero/constant';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { LANDING_THEME_ID } from '../../page/knowledge-landing/constant';

export const Component: React.FC<{ title?: TITLE }> = ({
  title = TITLE.KNOWLEDGE,
}) => {
  const history = useHistory();

  const handleRedirect = (id: string) => {
    history.push(`/knowledge/landing/${id}`);
    scrollToSection(LANDING_ID_ENUM.HOME);
  };

  return (
    <CardContainer>
      {title == TITLE.KNOWLEDGE ? (
        <CardHeader>
          <Icon src="./assets/svg/file.svg" alt="file" />
          <CardContainerTitle size="heading" fontFamily="e-UkraineHead">
            {i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_TEXT.TITLE')}
          </CardContainerTitle>
        </CardHeader>
      ) : (
        <CardHeader style={{ paddingBottom: '24px' }}>
          {/* <Icon src="./assets/svg/file.svg" alt="file" /> */}
          <TextElem
            size="large"
            color="textPrimary"
            type="medium"
            fontFamily="e-UkraineHead"
          >
            {i18n.t('KNOWLEDGE_LANDING.TITLE.THEME')}
          </TextElem>
        </CardHeader>
      )}

      <GridContainer spacing={6} size="list">
        {CATALOG_ITEM_LIST.map((service, index) => (
          <CardBlock
            index={service.id}
            key={index}
            page={title}
            onClick={() => handleRedirect(service.id)}
          >
            <CardText>
              <Card>
                <ImgWrap>
                  <CardImg src={service.imgSrc} alt={service.imgAlt} />
                </ImgWrap>
                <div>
                  <CardTitle size="label" type="medium" tid={service.title} />
                  <CardSubtitle
                    size="semiSmall"
                    type="light"
                    color="textSecondary"
                    tid={service.subtitle}
                  />
                </div>
              </Card>
            </CardText>
          </CardBlock>
        ))}
      </GridContainer>
    </CardContainer>
  );
};

const ImgWrap = styled.div<{ index?: number }>`
  overflow: hidden;
  max-width: 328px;
  height: 200px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: 50% 50%;
  // padding: 0 ${Spacing(5)};
`;

const CardContainer = styled.div`
  // background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(14)} ${Spacing(30)};
  margin: 0 auto;
  // border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  // border-top-left-radius: 0px;
  // border-top-right-radius: 0px;
  // border-bottom-left-radius: 0px;
  // border-bottom-right-radius: 0px;
  width: 100%;
  max-width: ${Spacing(350)};
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100%;
  // background-image: url() !important;
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;

  @media (max-width: 1350px) {
    padding: ${Spacing(14)} ${Spacing(20)};
  }

  @media (max-width: 768px) {
    padding: ${Spacing(10)} ${Spacing(15)};
  }

  @media (max-width: 480px) {
    padding: ${Spacing(10)} ${Spacing(5)};
  }
`;

const CardBlock = styled.div<{ page: TITLE; index: string }>`
  display: grid;
  align-self: stretch;
  cursor: pointer;
  border: 1px solid ${COLOR_DATA[COLOR_ENUM.MODAL_BACKGROUND]};
  border-radius: ${Spacing(4)};
  padding: ${Spacing(5)} ${Spacing(7)};
  gap: ${Spacing(5)};
  max-width: ${({ page }) => (page == TITLE.KNOWLEDGE ? '376px' : '368px')};
  width: 100%;
  // min-height: 354px;
  height: 100%;
  background-color: ${COLOR_DATA[COLOR_ENUM.MODAL_BACKGROUND]};

  ${({ index }) => {
    if (index == LANDING_THEME_ID.SELF_ESTEEM) {
      return css`
        @media (max-width: 768px) {
          img {
            transform: scaleX(1.2);
          }
        }
      `;
    }
    if (index == LANDING_THEME_ID.DEPRESSION) {
      return css`
        img {
          transform: scaleX(-1) scaleY(1.2);
        }
        @media (max-width: 768px) {
          img {
            transform: scaleX(-1.2) scaleY(1.3);
          }
        }
        @media (max-width: 420px) {
          img {
            transform: scaleX(-1.2) scaleY(1.2);
          }
        }
        @media (max-width: 360px) {
          img {
            transform: scaleX(-1.2);
          }
        }
      `;
    }
    if (index == LANDING_THEME_ID.MENTAL_TRAUMA) {
      return css`
        img {
          transform: scaleX(-1) scaleY(1.2);
        }

        @media (max-width: 768px) {
          img {
            transform: scaleX(-1.1) scaleY(1.1);
          }
        }
      `;
    }
  }}

  @media (max-width: 768px) {
    max-width: 355px;
    padding: ${Spacing(5)};
  }
`;

const CardText = styled.div`
  display: grid;
  gap: 0px;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  padding-bottom: ${Spacing(8)};
  gap: 12px;

  @media (max-width: 768px) {
    span {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]}px;
    }
  }
`;

const Icon = styled.img`
  // margin-bottom: 8px;
  width: 18px
  height: 18px;
`;

const CardTitle = styled(TextElem)`
  font-size: ${Spacing(4.5)};
  padding-bottom: ${Spacing(2)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN]}px;
    padding-bottom: ${Spacing(1.5)};
  }
`;

const CardContainerTitle = styled(TextElem)`
  // padding-bottom: ${Spacing(3)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};

  // @media (max-width: 768px) {
  //   font-size: ${Spacing(15)};
  // }

  // // @media (max-width: 480px) {
  // //   font-size: ${Spacing(5)};
  // // }
`;

const CardSubtitle = styled(TextElem)`
  // font-size: ${Spacing(3.5)};
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]} !important;
  // line-height: 160%;
  line-height: 21px;
  align-self: bottom;

  @media (max-width: 768px) {
    line-height: 18px;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.CHIP]}px;
  }
`;

const CardImg = styled.img`
  // padding-bottom: ${Spacing(4)};
  // max-width: 328px;
  width: 100%;
  height: 200px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.BUTTON]}px;
  overflow: hidden;
`;

const GridContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${Spacing(4)};
  // row-gap: ${Spacing(8)};

  @media (max-width: 1124px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled(CardElem)`
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr;
  gap: ${Spacing(5)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }

  @media (max-width: 768px) {
    gap: ${Spacing(4)};
  }
`;

export default Component;
