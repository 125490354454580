import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_DATA_NAME,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { action as fetch, getAction, removeAction } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { phoneOperator, phoneUA, required } from '../../lib/validation/service';
import { USER_ITEM_DATA_INTER } from '../../data/user/constant';
import { convertUser } from '../../data/user/convert';
import { convert } from './convert';
import { appPreloadAllLocalStateData } from '../../data/app';
import { actionTimezone } from '../profile-psycho-update-data/action';
import { TIMEZONE_VIEW, TIMEZONE_ENUM } from '../../data/user/timezone';

const config = {
  [FORM_VALUE_ENUM.NAME]: [required],
  [FORM_VALUE_ENUM.PHONE]: [phoneOperator, phoneUA],
  [FORM_VALUE_ENUM.REQUEST_LIST]: [],
  [FORM_VALUE_ENUM.REQUEST_CUSTOM]: [],
  [FORM_VALUE_ENUM.LGBT_FRENDLY]: [],
  [FORM_VALUE_ENUM.AGE]: [],
  [FORM_VALUE_ENUM.GENDER]: [],
  [FORM_VALUE_ENUM.METHOD_LIST]: [],
  [FORM_VALUE_ENUM.METHOD_CUSTOM]: [],
  [FORM_VALUE_ENUM.NEED_GENDER]: [],
  [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: [],
  [FORM_VALUE_ENUM.NEED_AGE]: [],
  [FORM_VALUE_ENUM.ABOUT]: [],
  [FORM_VALUE_ENUM.TIMEZONE]: [required],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  const [data, setData] = useState<USER_ITEM_DATA_INTER | undefined>(undefined);
  const [isTimezoneChangeModal, setIsTimezoneChangeModal] = useState(false);
  const offsetInMinute = new Date().getTimezoneOffset();
  const offsetInHour = -offsetInMinute / 60;

  const onSuccess = () => {
    if (isTimezoneChangeModal) {
      setIsTimezoneChangeModal(false);
    }

    dispatch(appPreloadAllLocalStateData());

    return query.invalidateQueries(MODULE_NAME);
  };

  const query = useQueryClient();
  const dispatch = useDispatch();

  const action = useMutation((d: FORM_VALUE_INTER) => dispatch(fetch(d)), {
    onSuccess,
  });

  const updateTimezoneAction = useMutation(actionTimezone, {
    onSuccess,
  });

  const {
    isSuccess: preFetchSuccess,
    isLoading: preFetchIsLoading,
    isError: preFetchIsError,
    refetch,
  } = useQuery(MODULE_NAME, getAction, {
    onSuccess: async (preFetch: any) => {
      const convertedData = convertUser(preFetch);

      if (offsetInHour !== Number(preFetch?.timezone)) {
        setIsTimezoneChangeModal(true);
      }

      setData(convertedData);
    },
  });

  const onRefetch = () => {
    refetch();
  };

  const deleteAction = useMutation(
    (id: string | null) => {
      return removeAction(id);
    },
    {
      onSuccess: () => {
        query.invalidateQueries(MODULE_DATA_NAME);
        query.invalidateQueries(MODULE_NAME);
      },
    },
  );

  const onDelete = () => {
    if (data?.photo?.id) {
      const id = data.photo?.id;
      return deleteAction.mutate(id);
    }
  };

  const initialValues = {
    [FORM_VALUE_ENUM.NAME]: data?.name || 'Без імені',
    [FORM_VALUE_ENUM.PHONE]: data?.phone || '',
    [FORM_VALUE_ENUM.REQUEST_LIST]: data?.requestList || [],
    [FORM_VALUE_ENUM.REQUEST_CUSTOM]: data?.requestCustom || '',
    [FORM_VALUE_ENUM.LGBT_FRENDLY]: data?.lgbtFrendly || null,
    [FORM_VALUE_ENUM.AGE]: data?.age || null,
    [FORM_VALUE_ENUM.GENDER]: data?.gender || null,
    [FORM_VALUE_ENUM.METHOD_LIST]: data?.methodList || [],
    [FORM_VALUE_ENUM.METHOD_CUSTOM]: data?.methodCustom || '',
    [FORM_VALUE_ENUM.NEED_GENDER]: data?.needGender || null,
    [FORM_VALUE_ENUM.NEED_AGE]: data?.needAge || null,
    [FORM_VALUE_ENUM.ABOUT]: data?.about || '',
    [FORM_VALUE_ENUM.TIMEZONE]: data?.timezone || offsetInHour,
    [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: data?.psychoCategory || '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);

      return action.mutate(convertedValues);
    },
    enableReinitialize: true,
  });

  const onCloseTimezoneModal = () => setIsTimezoneChangeModal(false);

  const changedTimezone = {
    label: TIMEZONE_VIEW[offsetInHour as TIMEZONE_ENUM],
    value: offsetInHour,
  };

  const defaultTimezone = {
    label:
      TIMEZONE_VIEW[formik.values[FORM_VALUE_ENUM.TIMEZONE] as TIMEZONE_ENUM],
    value: Number(formik.values[FORM_VALUE_ENUM.TIMEZONE]),
  };

  const handleUpdateTimezone = (timezone: number) => {
    return updateTimezoneAction.mutate(timezone);
  };

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetchIsLoading || deleteAction.isLoading) {
      return true;
    }
  };

  const isUploading = () => {
    if (deleteAction.isLoading) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const isLoading = () => {
    if (preFetchIsLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetchSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetchIsError && !preFetchIsLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };

  return (
    <Component
      onCloseTimezoneModal={onCloseTimezoneModal}
      changedTimezone={changedTimezone}
      defaultTimezone={defaultTimezone}
      handleUpdateTimezone={handleUpdateTimezone}
      isTimezoneChangeModal={isTimezoneChangeModal}
      isSuccessDelete={deleteAction.isSuccess}
      onRefetch={onRefetch}
      photoUrl={data?.photo?.url}
      deleteAction={onDelete}
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoading={isLoading()}
      isUploading={isUploading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      isSuccessAction={action.isSuccess}
    />
  );
};
