import React from 'react';
import styled, { css } from 'styled-components';

import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { GridElem } from '../../common/grid';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
} from '../../theme/size';
import { i18n } from '../../lib/lang';
import { LANDING_ID_ENUM, LANDING_ID_PROPS } from '../home-header/constant';
import useIntersectionObserver from './hook/useIntersectionObserver';
import { LINK } from './constant';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../theme/value';

export const Component: React.FC<LANDING_ID_PROPS> = ({ handleNavClick }) => {
  const activeSection = useIntersectionObserver();
  return (
    <PaddingContainer>
      <FooterContainer>
        <HomeHeader>
          <LogoContainer>
            <img src="./assets/svg/header/syayvoLogo.svg" alt="syaivo Logo" />
          </LogoContainer>
          <ButtonsContainer>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.TELEGRAM`)}>
                <img
                  src="./assets/svg/telegram_logo_black.svg"
                  alt="telegram_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.FACEBOOK`)}>
                <img
                  src="./assets/svg/facebook_logo_black.svg"
                  alt="facebook_logo"
                />
              </a>
            </ButtonElem>
            <ButtonElem type="social">
              <a target="_blank" href={i18n.t(`LANDING.SOCIALS.INSTAGRAM`)}>
                <img
                  src="./assets/svg/instagram_logo_black.svg"
                  alt="instagram_logo"
                />
              </a>
            </ButtonElem>
          </ButtonsContainer>
        </HomeHeader>
        <GridContainer>
          <FlexContainer>
            {Object.values(LANDING_ID_ENUM).map((section, index, array) => (
              <NavLink
                key={section}
                // href={`#${section}`}
                href={
                  index === array.length - 2
                    ? '/faq'
                    : index === array.length - 1
                    ? '/knowledge'
                    : `/home#${section}`
                }
                onClick={
                  index === array.length - 2 || index === array.length - 1
                    ? undefined
                    : (e) => {
                        e.preventDefault();
                        window.location.href = `/home#${section}`;
                      }
                }
                // onClick={handleNavClick(section)}
                // isActive={activeSection === section}
              >
                <TextLink
                  oneLine
                  color="textSecondary"
                  size="semiSmall"
                  tid={i18n.t(`LANDING.HOME_FOOTER.NAV_BAR.${section}`)}
                />
              </NavLink>
            ))}
            <LogoContainer>
              <Dot />
            </LogoContainer>
            {LINK.map((link) => {
              const hrefText = i18n.t(link.href);

              return (
                <Link key={link.tid} target="_blank" href={hrefText}>
                  <TextLink
                    oneLine
                    color="textSecondary"
                    size="semiSmall"
                    tid={link.tid}
                  />
                </Link>
              );
            })}
          </FlexContainer>
        </GridContainer>
        <NavBarFooter>
          <Copyrite
            color="textSecondary"
            size="semiSmall"
            fontFamily="e-UkraineHead"
            tid="LANDING.HOME_FOOTER.COPYRIGHT"
          />
          <ContactContainer>
            <PhoneNumberText href="tel:+380980119429">
              <TextElem
                color="textPrimary"
                size="semiSmall"
                fontFamily="e-UkraineHead"
                tid="LANDING.HOME_FOOTER.NUMBER"
              />
            </PhoneNumberText>

            <Dot />
            <AMail href={`mailto:${i18n.t('LANDING.HOME_FOOTER.MAIL')}`}>
              <TextElemMail
                color="textPrimary"
                size="semiSmall"
                fontFamily="e-UkraineHead"
                tid="LANDING.HOME_FOOTER.MAIL"
              />
            </AMail>
          </ContactContainer>
        </NavBarFooter>
      </FooterContainer>
    </PaddingContainer>
  );
};

const AMail = styled.a`
  line-height: 1em;
`;

const ContactContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${Spacing(3)};
  align-items: center;

  @media screen and (max-width: 460px) {
    flex-direction: column;
  }
`;

const TextElemMail = styled(TextElem)`
  text-decoration: underline;
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

  &:hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
  }
`;
const PhoneNumberText = styled.a`
  span {
    text-decoration: underline;
    transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.DEFAULT]};

    &:hover {
      opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.DEFAULT]};
    }
  }
`;

export const PaddingContainer = styled.div`
  padding: ${Spacing(3)};
  background: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  max-width: ${Spacing(350)};
  margin: 0 auto;
  display: grid;
  gap: ${Spacing(5)};
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    padding-top: ${Spacing(6)};
  }
`;

const Copyrite = styled(TextElem)`
  line-height: 170%;
`;

const TextLink = styled(TextElem)`
  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }
`;

const GridContainer = styled(GridElem)`
  font-family: 'e-Ukraine', sans-serif;
  display: grid;
  padding: ${Spacing(5)} ${Spacing(0)};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const FlexContainer = styled.div`
  display: flex;
  gap: ${Spacing(5)};
  flex-wrap: wrap;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: ${Spacing(5)};
  }
  * > * > * > {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]};
  }
`;

const Link = styled.a`
  text-decoration: none;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]}px;

  @media (max-width: 360px){
  span {
    font-size ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]}px;
  }
  }
`;

const NavBarFooter = styled.div`
  display: flex;
  height: ${Spacing(9)};
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    text-align: center;
    padding: 0 ${Spacing(2)};
    flex-direction: column;
    gap: ${Spacing(2)};
    height: unset;
  }
`;

const ButtonsContainer = styled.div`
  @media (max-width: 768px) {
    gap: ${Spacing(2)};
    justify-content: space-around;
  }

  @media (max-width: 350px) {
    display: flex;
    justify-content: space-between;
    padding: ${Spacing(4)};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${Spacing(4)};

  @media (max-width: 768px) {
    gap: ${Spacing(2)};
  }
`;

const Dot = styled.div`
  width: ${Spacing(0.5)};
  height: ${Spacing(5)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  opacity: 0.35;

  @media screen and (max-width: 460px) {
    display: none;
  }
`;

const HomeHeader = styled.div`
  padding: 0px ${Spacing(6)};
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: ${Spacing(21)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
  margin: 0 auto;
  display: flex;
  gap: ${Spacing(4)};
  justify-content: space-between;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  z-index: 200;

  @media (max-width: 768px) {
    height: fit-content;
    display: flex;
    justify-content: space-between;
    padding: ${Spacing(4)};
  }

  @media (max-width: 350px) {
    display: block;
  }
`;

const FooterContainer = styled.div`
  padding: ${Spacing(10)} ${Spacing(15)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  max-width: ${Spacing(350)};
  margin: 0 auto;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;

  @media (max-width: 768px) {
    padding: ${Spacing(5)};
  }

  @media (max-width: 480px) {
    padding: ${Spacing(2.5)};
  }
`;

// const activeStyles = css`
//   color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
// `;

// ${({ isActive }) => isActive && activeStyles};

const NavLink = styled.a<{ isActive?: boolean }>`
  text-decoration: none;
  display: inline-block;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  }
`;
