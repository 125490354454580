import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  FORM_VALUE_ENUM,
  GENDER_OPTION_LIST,
  LGBT_OPTION_LIST,
} from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import { HeaderSecondary } from '../../common/header-secondary';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload';
import { CATEGORY_OPTION_LIST } from '../profile-psycho-create-category/constant';
import {
  METHOD_OPTION_LIST,
  REQUEST_OPTION_LIST,
} from '../profile-psycho-update-specialization/constant';
import { multiValueContainer } from '../../common/select/frame/multi-value';
import { ChangeTimezoneModal } from '../profile-psycho-update-data/frame/change-timezone-modal';
import { OPTION_INTER } from '../../data/user/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isUploading?: boolean;
  deleteAction: Function;
  isSuccessAction: boolean;
  photoUrl?: string;
  onRefetch: Function;
  isSuccessDelete: boolean;
  isTimezoneChangeModal: boolean;
  onCloseTimezoneModal: Function;
  changedTimezone: OPTION_INTER;
  defaultTimezone: OPTION_INTER;
  handleUpdateTimezone: (timezone: number) => void;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  onChangeSelect,
  isUploading,
  deleteAction,
  isSuccessAction,
  photoUrl,
  onRefetch,
  isSuccessDelete,
  isTimezoneChangeModal,
  onCloseTimezoneModal,
  defaultTimezone,
  changedTimezone,
  handleUpdateTimezone,
}) => {
  return (
    <>
      {isUploading && <LoaderElem />}

      {isSuccessDelete && (
        <AlertActionElem type="success" tid="USER.FILE.DELETE" />
      )}
      <FormElemStyled gap={7} onSubmit={formik.handleSubmit}>
        <HeaderSecondary
          titleTid="USER.USER.TITLE.NAME"
          prefixTid="USER.USER.TITLE.PREFIX"
          sufixTid="USER.USER.TITLE.SUFIX"
          postfixTid="USER.USER.TITLE.POSTFIX"
        />

        {isLoading && <SkeletonFormElem />}

        {isSuccess && (
          <>
            <ContentContainerElem>
              <TextElem
                tid="USER.USER.PHOTO_FROFILE"
                type="medium"
                size="main"
                color="buttonText"
              />
              <FileItemUploadContainer
                type="photo"
                defaultValue={photoUrl}
                onSuccess={onRefetch}
                onReset={() => {
                  deleteAction();
                  onRefetch();
                }}
                title="COMMON.FILE.IMAGE_TITLE"
              />
            </ContentContainerElem>
            <ContentContainerElem>
              <TextElem
                tid="USER.USER.INFORMATION"
                type="medium"
                size="main"
                color="buttonText"
              />
              <GridElem spacing={4}>
                <FieldTextElem
                  isDot
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.USER.NAME"
                  placeholder="USER.USER.NAME_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />
                <DoubleContainerElem>
                  <SelectElem
                    placeholder="USER.USER.TIMEZONE_PLACEHOLDER"
                    isDot
                    name={FORM_VALUE_ENUM.TIMEZONE}
                    onChange={setFieldValue}
                    options={TIMEZONE_OPTION_LIST}
                    title="USER.USER.TIMEZONE"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                    error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                    value={setValue(
                      TIMEZONE_OPTION_LIST,
                      FORM_VALUE_ENUM.TIMEZONE,
                    )}
                  />
                  <FieldTextElem
                    type="phone"
                    name={FORM_VALUE_ENUM.PHONE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.USER.PHONE"
                    placeholder="USER.USER.PHONE_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                    error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                  />
                </DoubleContainerElem>
                <DoubleContainerElem>
                  <FieldTextElem
                    type="number"
                    name={FORM_VALUE_ENUM.AGE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.USER.AGE"
                    placeholder="USER.USER.AGE_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.AGE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.AGE)}
                    error={isFieldError(FORM_VALUE_ENUM.AGE)}
                  />
                  <SelectElem
                    placeholder="USER.USER.GENDER_PLACEHOLDER"
                    name={FORM_VALUE_ENUM.GENDER}
                    onChange={setFieldValue}
                    options={GENDER_OPTION_LIST}
                    title="USER.USER.GENDER"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                    error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                    value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
                  />
                </DoubleContainerElem>
              </GridElem>
              <TextElem
                tid="USER.USER.PSYCHO_INFORMATION"
                type="medium"
                size="main"
                color="buttonText"
              />
              <GridElem spacing={4}>
                <FieldTextAreaElem
                  name={FORM_VALUE_ENUM.ABOUT}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getFieldValue(FORM_VALUE_ENUM.ABOUT)}
                  title="USER.USER.ABOUT"
                  placeholder="USER.USER.ABOUT_PLACEHOLDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT)}
                  error={isFieldError(FORM_VALUE_ENUM.ABOUT)}
                />

                <SelectElem
                  placeholder="USER.USER.REQUEST_LIST_PLACEHOLDER"
                  isMulti
                  name={FORM_VALUE_ENUM.REQUEST_LIST}
                  options={REQUEST_OPTION_LIST}
                  title="USER.USER.REQUEST_LIST"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
                  error={isFieldError(FORM_VALUE_ENUM.REQUEST_LIST)}
                  value={getFieldValue(FORM_VALUE_ENUM.REQUEST_LIST)}
                  onChange={onChangeSelect}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  customComponents={{
                    MultiValueContainer: multiValueContainer,
                  }}
                />
                <FieldTextAreaElem
                  name={FORM_VALUE_ENUM.REQUEST_CUSTOM}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getFieldValue(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
                  placeholder="USER.USER.REQUEST_CUSTOM_PLACEHOLDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
                  error={isFieldError(FORM_VALUE_ENUM.REQUEST_CUSTOM)}
                />

                <SelectElem
                  placeholder="USER.USER.METHOD_LIST_PLACEHOLDER"
                  isMulti
                  name={FORM_VALUE_ENUM.METHOD_LIST}
                  options={METHOD_OPTION_LIST}
                  title="USER.USER.METHOD_LIST"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
                  error={isFieldError(FORM_VALUE_ENUM.METHOD_LIST)}
                  value={getFieldValue(FORM_VALUE_ENUM.METHOD_LIST)}
                  onChange={onChangeSelect}
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  customComponents={{
                    MultiValueContainer: multiValueContainer,
                  }}
                />
                <FieldTextAreaElem
                  name={FORM_VALUE_ENUM.METHOD_CUSTOM}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={getFieldValue(FORM_VALUE_ENUM.METHOD_CUSTOM)}
                  placeholder="USER.USER.METHOD_CUSTOM"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
                  error={isFieldError(FORM_VALUE_ENUM.METHOD_CUSTOM)}
                />

                {/* <SelectElem
                  placeholder="USER.USER.LGBT_FRENDLY_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.LGBT_FRENDLY}
                  onChange={setFieldValue}
                  options={LGBT_OPTION_LIST}
                  title="USER.USER.LGBT_FRENDLY"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.LGBT_FRENDLY)}
                  error={isFieldError(FORM_VALUE_ENUM.LGBT_FRENDLY)}
                  value={setValue(
                    LGBT_OPTION_LIST,
                    FORM_VALUE_ENUM.LGBT_FRENDLY,
                  )}
                /> */}
                <SelectElem
                  placeholder="USER.USER.PSYCHO_CATEGORY_PLACEHOLDER"
                  name={FORM_VALUE_ENUM.PSYCHO_CATEGORY}
                  onChange={setFieldValue}
                  options={CATEGORY_OPTION_LIST}
                  title="USER.USER.PSYCHO_CATEGORY"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
                  error={isFieldError(FORM_VALUE_ENUM.PSYCHO_CATEGORY)}
                  value={setValue(
                    CATEGORY_OPTION_LIST,
                    FORM_VALUE_ENUM.PSYCHO_CATEGORY,
                  )}
                />
                <DoubleContainerElem>
                  <SelectElem
                    placeholder="USER.USER.NEED_GENDER_PLACEHOLDER"
                    name={FORM_VALUE_ENUM.NEED_GENDER}
                    onChange={setFieldValue}
                    options={GENDER_OPTION_LIST}
                    title="USER.USER.NEED_GENDER"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_GENDER)}
                    error={isFieldError(FORM_VALUE_ENUM.NEED_GENDER)}
                    value={setValue(
                      GENDER_OPTION_LIST,
                      FORM_VALUE_ENUM.NEED_GENDER,
                    )}
                  />

                  <FieldTextElem
                    type="number"
                    name={FORM_VALUE_ENUM.NEED_AGE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.USER.NEED_AGE"
                    placeholder="USER.USER.NEED_AGE_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.NEED_AGE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.NEED_AGE)}
                    error={isFieldError(FORM_VALUE_ENUM.NEED_AGE)}
                  />
                </DoubleContainerElem>

                <TitleContainer>
                  <Dot />
                  <TextElem
                    tid="USER.USER.REQUIRED"
                    type="light"
                    size="input"
                    color="textSecondary"
                  />
                </TitleContainer>

                <ButtonElem
                  disabled={isSubmitDisabled()}
                  type="submit"
                  tid="CATEGORY.DATA.BUTTON"
                />
              </GridElem>
              {isError && <AlertActionElem text={errorMessage} />}
              {isSuccessAction && (
                <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
              )}
            </ContentContainerElem>
          </>
        )}
      </FormElemStyled>
      <ChangeTimezoneModal
        isOpen={isTimezoneChangeModal}
        onClose={onCloseTimezoneModal}
        defaultTimezone={defaultTimezone}
        changedTimezone={changedTimezone}
        handleUpdate={handleUpdateTimezone}
      />
    </>
  );
};

const FormElemStyled = styled(FormElem)`
  @media screen and (max-width: 1160px) {
    margin-top: ${Spacing(8)};
  }
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
