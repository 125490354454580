import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import dayjs from 'dayjs';

import { useSelector } from '../../lib/store';
import { STORE_INTER } from '../../data/auth/constant';

import {
  ACTION_ERROR_INTER,
  DATA_INTER,
  DATA_RAW_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';

import { action as fetch, getAction } from './action';
import { convert } from './convert';
import { validation } from '../../lib/validation';
import { FormikValues, useFormik } from 'formik';
import { required } from '../../lib/validation/service';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { CONSULTATION_PAGE_PATH_DYNAMIC } from '../../page/consultation';
import { useFetchTime } from '../../lib/util/useFetchTime';
import { useFetchDay } from '../../lib/util/useFetchDay';
import { AUTH_LOGIN_ORDER_LOCAL_STORE } from '../order-auth-signup/constant';

export const Container: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [activeId, setActiveId] = useState(id);

  const history = useHistory();

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const timezone = auth.user ? auth.user.timezone : null;

  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isConfirmEmail = state?.isConfirmEmail;
  const isAuth = state ? state.logged && state?.isConfirmEmail : null;

  const preFetch = useQuery([MODULE_NAME, id], () => getAction(activeId || id));

  const getData = (): DATA_INTER | undefined => {
    const data = preFetch.data as unknown as DATA_RAW_INTER;
    if (data) {
      return convert(data);
    }
  };

  const data = getData();

  const onSuccess = (data: any) => {
    history.push(CONSULTATION_PAGE_PATH_DYNAMIC(data.id));
  };

  const action = useMutation(fetch, { onSuccess });

  const config = {
    [FORM_VALUE_ENUM.CATEGORY]: [required],
    [FORM_VALUE_ENUM.COMMENT]: [],
    [FORM_VALUE_ENUM.DATE]: [required],
    [FORM_VALUE_ENUM.TIME]: [required],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.CATEGORY]: data?.category || '',
    [FORM_VALUE_ENUM.COMMENT]: '',
    [FORM_VALUE_ENUM.DATE]: '',
    [FORM_VALUE_ENUM.TIME]: '',
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (values: FORM_VALUE_INTER | any) => {
      return action.mutate({
        ...values,
        category: values[FORM_VALUE_ENUM.CATEGORY],
        duration: data?.duration,
        date: values.time,
        psychologist: data?.user.id,
        price: data?.price,
      });
    },
  });

  const {
    timeOption,
    isLoading: isTimeLoading,
    isError: isTimeError,
  } = useFetchTime({
    selectedDate: formik.values[FORM_VALUE_ENUM.DATE],
    psychologistId: data?.user.id || '',
    clientTimezone: timezone,
    duration: data?.duration,
  });

  const {
    dayArr,
    isLoading: isDayLoading,
    isError: isDayError,
    isSuccess: isDaySuccess,
    refetch,
  } = useFetchDay({
    psychologistId: data?.user.id || '',
    duration: data?.duration,
  });

  useEffect(() => {
    if (activeId) {
      preFetch.refetch().then(() => {
        formik.resetForm();
        refetch();
      });
    }
  }, [activeId]);
  const authLoginOrderPath = localStorage.getItem(AUTH_LOGIN_ORDER_LOCAL_STORE);

  useEffect(() => {

    if (state.logged && authLoginOrderPath) {
      localStorage.removeItem(AUTH_LOGIN_ORDER_LOCAL_STORE);
    }
  }, []);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
    if (!isAuth) {
      return true;
    }

    if (!isConfirmEmail) {
      return true;
    }

    if (isDayLoading) {
      return true;
    }
  };

  const isFieldDisabled = () => {
    if (!isAuth) {
      return true;
    }

    if (!isConfirmEmail) {
      return true;
    }

    if (isTimeLoading) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (isTimeError && !isTimeLoading && getErrorMessage()) {
      return true;
    }

    if (isDayError) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
    if (name === FORM_VALUE_ENUM.CATEGORY) {
      setActiveId(e.id);
    }

    if (name === FORM_VALUE_ENUM.DATE) {
      formik.setFieldValue(FORM_VALUE_ENUM.TIME, '');
    }

    formik.setFieldValue(name, e.value);
  };

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      availableDate={dayArr}
      errorMessage={getErrorMessage()}
      isError={isError()}
      isTimeLoading={isTimeLoading}
      timeOption={timeOption}
      timezone={timezone}
      setValue={setValue}
      setFieldValue={setFieldValue}
      isLoading={preFetch.isLoading}
      isSuccess={preFetch.isSuccess && isDaySuccess}
      data={data}
      formik={formik}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      isLogged={state.logged}
      isConfirmEmail={isConfirmEmail}
      isFieldDisabled={isFieldDisabled}
    />
  );
};
