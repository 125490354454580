import React from 'react';
import { components } from 'react-select';
import { TextElem } from '../../text';

export const CustomNoOptionsMessage = (props: any) => {
  const customMessage = props.additionalProp;

  return (
    <components.NoOptionsMessage {...props}>
      <TextElem size="small" color="textSecondary" tid={customMessage} />
    </components.NoOptionsMessage>
  );
};
