import { i18n } from '../../lib/lang';
import { LANDING_THEME_ID } from '../../page/knowledge-landing/constant';

export const MODULE_NAME = 'WELLCOME_LOGOUT_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum TITLE {
  KNOWLEDGE = 1,
  KNOWLEDGE_LANDING = 2
}

export const CATALOG_ITEM_LIST = [
  {
    id: LANDING_THEME_ID.DEPRESSION,
    imgSrc: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.3.IMG_ALT')}`,
    title: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.3.TITLE')}`,
    subtitle: `${i18n.t(
      'KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.3.SUBTITLE',
    )}`,
  },
  {
    id: LANDING_THEME_ID.SELF_ESTEEM,
    imgSrc: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.1.IMG_ALT')}`,
    title: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.1.TITLE')}`,
    subtitle: `${i18n.t(
      'KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.1.SUBTITLE',
    )}`,
  },
  {
    id: LANDING_THEME_ID.MENTAL_TRAUMA,
    imgSrc: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_SRC')}`,
    imgAlt: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.5.IMG_ALT')}`,
    title: `${i18n.t('KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.5.TITLE')}`,
    subtitle: `${i18n.t(
      'KNOWLEDGE.HOME_CATALOG.CATALOG_ITEM_LIST.5.SUBTITLE',
    )}`,
  },
];
