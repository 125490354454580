import React from 'react';
import styled from 'styled-components';

import { AlertActionElem } from '../../common/alert-action';
import { LoaderElem } from '../../common/loader';
import { ButtonElem } from '../../common/button';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
} from '../../theme/value';
import tickSquare from '../../asset/svg/button/tick-square.svg';
import { FORM_VALUE_ENUM } from './constant';
import { SelectMultiElem } from '../../common/select-multi';
import { multiValueContainer } from '../../common/select/frame/multi-value';
import { OPTION_ITEM_DATA } from '../../common/select/constant';
import { TextElem } from '../../common/text';

export const Component: React.FC<{
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isSubmitDisabled: Function;
  isSuccess: boolean;
  handleSuccess: () => void;
  onChangeSelect: Function;
  getFieldValue: Function;
  timeOption: OPTION_ITEM_DATA[];
  day: string;
}> = ({
  isLoading,
  isError,
  errorMessage,
  isSuccess,
  handleSuccess,
  isSubmitDisabled,
  onChangeSelect,
  getFieldValue,
  timeOption,
  day,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}
      {isError && <AlertActionElem text={errorMessage} />}
      {isSuccess && (
        <AlertActionElem type="success" tid="SCHEDULE.WEEKEND.SUCCESS" />
      )}
      <Card>
        <AddCardContainer>
          <Container>
            <TextElem size="semiSmall" color="textPrimary" tid={day} />
          </Container>
          <SelectMultiElem
            isMulti
            isClearable
            minHeight="48px"
            isDisabled={isLoading}
            dynamic
            options={timeOption}
            name={FORM_VALUE_ENUM.TIME}
            value={getFieldValue(FORM_VALUE_ENUM.TIME)}
            onChange={onChangeSelect}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            placeholder="SCHEDULE.CREATE.TIME_PLACEHOLDER"
            customComponents={{ MultiValueContainer: multiValueContainer }}
          />

          <WeekendButton disabled={isSubmitDisabled()} onClick={handleSuccess}>
            <ImgStyled src={tickSquare} alt="calendar" />
          </WeekendButton>
        </AddCardContainer>
      </Card>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(3)};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(3)} ${Spacing(4)};
  justify-content: center;
`;

const ImgStyled = styled.img`
  width: ${Spacing(5)};
  height: ${Spacing(5)};
`;

const WeekendButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  padding: ${Spacing(1)} 14px;
  min-height: 48px;

  transition: opacity ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};

  :disabled {
    opacity: 0.7;
  }

  :hover:not(:disabled) {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const AddCardContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  grid-template-columns: 140px 1fr 48px;

  @media screen and (max-width: 1160px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;
