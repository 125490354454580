import React, { useEffect, useState } from 'react';
import { Component } from './component';

export const Container: React.FC = () => {
  const [activeQuestion, setActionQuestion] = useState<number | null>(null);
  const [activeIcon, setIconActive] = useState(
    './assets/svg/landing/arrow.svg',
  );

  const toggleQuestion = (index: number) => {
    setActionQuestion((prev) => (prev === index ? null : index));
    setIconActive('./assets/svg/faq/arrow-active.svg');
  };

  return (
    <Component
      activeQuestion={activeQuestion}
      toggleQuestion={toggleQuestion}
      activeIcon={activeIcon}
    />
  );
};
