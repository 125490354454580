export const MODULE_NAME = 'KNOWLEDGE_LANDING_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum KNOWLEDGE_LANDING_ID_ENUM {
  HOME = 'MAIN',
  SERVICES = 'QUESTION',
  ABOUT = 'ABOUT',
  FAQ = 'FAQ',
  KNOWLEDGE = 'KNOWLEDGE',
}

export type KNOWLEDGE_LANDING_ID_TYPE = `${KNOWLEDGE_LANDING_ID_ENUM}`;

export interface KNOWLEDGE_LANDING_ID_PROPS {
  handleNavClick: (
    id: KNOWLEDGE_LANDING_ID_TYPE,
  ) => (e: React.MouseEvent<HTMLAnchorElement>) => void;
}
