import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutFooterElem } from '../../common/layout-footer';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { DesktopMenuAppContainer } from '../../epic/desktop-menu-app';
import { HeaderContainer } from '../../epic/header';
import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import { ScheduleListContainer } from '../../epic/schedule-list';
import { MobileMenuContainer } from '../../epic/mobile-menu';
import { ScheduleItemCreateContainer } from '../../epic/schedule-item-create';
import { Spacing } from '../../theme';
import { ScheduleListRegularContainer } from '../../epic/schedule-list-regular';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <HeaderContainer
          icon={calendarIcon}
          title="SCHEDULE.PAGE.HEADER"
          size="large"
        />

        <LayoutElem size="large">
          <LayoutSectionElem spacing={5}>
            <LayoutPage>
              <ScheduleListRegularContainer />
              <ScheduleContainer>
                <ScheduleItemCreateContainer />
                <ScheduleListContainer />
              </ScheduleContainer>
            </LayoutPage>
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
      <DesktopMenuAppContainer />
      <LayoutFooterElem>
        <MobileMenuContainer />
      </LayoutFooterElem>
    </PageElem>
  );
};

const ScheduleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(5)};
`;

const LayoutPage = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${Spacing(5)};

  @media screen and (max-width: 1475px) {
    grid-template-columns: 1fr;
  }
`;
