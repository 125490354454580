import React from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { HomeCatalogContainer } from '../../epic/home-catalog';
import { HomeHeaderContainer } from '../../epic/home-header';
import { HomeHeroContainer } from '../../epic/home-hero';
import { HomeAboutContainer } from '../../epic/home-about';
import { HomeFooterContainer } from '../../epic/home-footer';
import { LANDING_ID_ENUM } from '../../epic/home-header/constant';
import styled from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';

export const Page: React.FC<RouteComponentProps> = () => {
  return (
    <PageElem background="active">
      <LayoutAppElem oneColumn>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeaderContainer />
        </SectionHome>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeroContainer />
        </SectionHome>
        <SectionService id={LANDING_ID_ENUM.SERVICES}>
          <HomeCatalogContainer />
        </SectionService>
        <SectionAbout id={LANDING_ID_ENUM.ABOUT}>
          <HomeAboutContainer />
        </SectionAbout>
        <SectionFooter id={LANDING_ID_ENUM.ABOUT}>
          <HomeFooterContainer />
        </SectionFooter>
      </LayoutAppElem>
    </PageElem>
  );
};

const SectionHome = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const SectionService = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionAbout = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionFooter = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
