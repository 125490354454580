import { OPTION_INTER } from '../../data/user/constant';

export const convert = (values: any) => {
  return {
    ...values,
    requestList:
      values?.requestList &&
      values?.requestList?.map((e: OPTION_INTER) => e.value).join(','),
    methodList:
      values?.methodList &&
      values?.methodList?.map((e: OPTION_INTER) => e.value).join(','),
    psychoCategory: values?.psychoCategory?.value,
  };
};
