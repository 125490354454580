import { API, FORM_VALUE_INTER } from './constant';
import { HttpRequest } from '../../lib/http';
import { Dispatch } from '../../lib/store';

export const action =
  (payload: FORM_VALUE_INTER) => async (dispatch: Dispatch) => {
    return HttpRequest({
      method: API.MAIN.TYPE,
      url: API.MAIN.URL,
      data: payload,
    });
  };

export const actionTimezone = (timezone: number) => {
  return HttpRequest({
    method: API.TIMEZONE_PATCH.TYPE,
    url: API.TIMEZONE_PATCH.URL,
    data: {
      timezone,
    },
  });
};

export const getAction = () => {
  return HttpRequest({
    method: API.GET.TYPE,
    url: API.GET.URL,
  });
};
