import styled from 'styled-components';
import { ModalElem } from '../../../common/modal';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { SelectElem } from '../../../common/select';
import { TIMEZONE_OPTION_LIST } from '../../../data/user/timezone';
import { ButtonElem } from '../../../common/button';
import { OPTION_INTER } from '../../../data/user/constant';
import { useState } from 'react';

export const ChangeTimezoneModal: React.FC<{
  isOpen: boolean;
  onClose: Function;
  defaultTimezone: OPTION_INTER;
  changedTimezone: OPTION_INTER;
  handleUpdate: (timezome: number) => void;
}> = ({ isOpen, onClose, changedTimezone, defaultTimezone, handleUpdate }) => {
  const [timezone, setTimezone] = useState(changedTimezone);

  return (
    <ModalElem onClose={onClose} open={isOpen}>
      <Container>
        <TextElem
          tid="USER.TIMEZONE.INFORMATION_TITLE"
          type="medium"
          size="main"
          color="buttonText"
        />
        <TextElem
          tid="USER.TIMEZONE.INFORMATION_DESCRIPTION"
          type="light"
          size="input"
          color="textSecondary"
        />
      </Container>
      <Grid margin="12px 0 0 0">
        <SelectElem
          options={TIMEZONE_OPTION_LIST}
          placeholder="USER.TIMEZONE.CHANGED"
          value={timezone}
          onChange={(e: any, value: any) => {
            setTimezone(value);
          }}
        />
        <SelectElem
          isDisabled
          placeholder="USER.TIMEZONE.CURRENT"
          value={defaultTimezone}
        />
      </Grid>
      <Grid margin="20px 0 0 0">
        <ButtonElem
          tid="USER.TIMEZONE.CHANGE_BUTTON"
          type="submit"
          onClick={() => handleUpdate(timezone.value)}
        />
        <ButtonElem
          tid="USER.TIMEZONE.CANCEL_BUTTON"
          type="submit"
          onClick={onClose}
        />
      </Grid>
    </ModalElem>
  );
};

const Grid = styled.div<{ margin?: string }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: ${Spacing(2)};
  margin: ${({ margin }) => margin};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(2)};
`;
