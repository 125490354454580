export const scrollToSection = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

export const scrollToSectionCenter = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
  }
};
