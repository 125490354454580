import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { config, FORM_VALUE_ENUM, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { convert } from './convert';
import {
  convertPsychoFilter,
  convertOptionArray,
} from '../../data/user/convert';
import {
  methodViewObj,
  requestViewObj,
  USER_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import {
  CATEGORY_ENUM,
  CATEGORY_VIEW,
} from '../profile-psycho-create-category/constant';
import { saveFormikValuesToLocalStorage } from '../../lib/util/formik';
import { addParamToUrl, getUrlParam } from '../../lib/util/urlParamUtil';

export const Container: React.FC<{
  setOption: Function;
}> = ({ setOption }) => {
  const category = getUrlParam(
    FORM_VALUE_ENUM.PSYCHO_CATEGORY,
  ) as unknown as CATEGORY_ENUM;
  const request = getUrlParam(FORM_VALUE_ENUM.REQUEST_LIST);
  const method = getUrlParam(FORM_VALUE_ENUM.METHOD_LIST);

  const [initialValues, setInitialValues] = useState<any>({
    [FORM_VALUE_ENUM.REQUEST_LIST]: request
      ? convertOptionArray(request.split(','), requestViewObj)
      : '',
    [FORM_VALUE_ENUM.METHOD_LIST]: method
      ? convertOptionArray(method.split(','), methodViewObj)
      : '',
    [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: category
      ? { label: CATEGORY_VIEW[category], value: Number(category) }
      : null,
  });

  const { auth } = useSelector((s: any) => ({
    auth: s[AUTH_MODULE_NAME],
  }));

  const isLogged = () => !!auth.logged;

  const preFetch = useQuery(MODULE_NAME, action, {
    onSuccess: (data: USER_ITEM_DATA_RAW_INTER) => {
      if (data === undefined) return;

      const convertData = convertPsychoFilter(data);

      setInitialValues({
        [FORM_VALUE_ENUM.REQUEST_LIST]: request
          ? convertOptionArray(request.split(','), requestViewObj)
          : convertData?.requestList,
        [FORM_VALUE_ENUM.METHOD_LIST]: method
          ? convertOptionArray(method.split(','), methodViewObj)
          : convertData?.methodList,
        [FORM_VALUE_ENUM.PSYCHO_CATEGORY]: category
          ? { value: category, label: CATEGORY_VIEW[category] }
          : convertData?.psychoCategory,
      });
    },
    enabled: isLogged(),
    refetchOnWindowFocus: true,
    refetchOnMount: true,
  });

  const formik: FormikValues = useFormik({
    initialValues,
    validate: (values) => validation(values, config),
    enableReinitialize: true,
    onSubmit: () => {},
  });

  const isLoading = () => {
    if (!isLogged()) {
      return false;
    }

    if (preFetch.isLoading) {
      return true;
    }
  };

  useEffect(() => {
    setOption(convert(formik.values));
    saveFormikValuesToLocalStorage(formik.values);
  }, [formik.values, category, request]);

  const handleArrayFieldChange = (name: string, e: any) => {
    formik.setFieldValue(...e);

    if (Array.isArray(e[1])) {
      const methodList = e[1];
      const paramValue = methodList.map((item) => item.value).join(',');

      addParamToUrl(name, paramValue);
    }
  };

  return (
    <Component
      handleArrayFieldChange={handleArrayFieldChange}
      formik={formik}
      isLoading={isLoading()}
    />
  );
};
