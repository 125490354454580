import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import { HomeCatalogContainer } from '../../epic/home-catalog';
import { HomeHeaderContainer } from '../../epic/home-header';
import { HomeHeroContainer } from '../../epic/home-hero';
import { HomeAboutContainer } from '../../epic/home-about';
import { HomeFooterContainer } from '../../epic/home-footer';
import { LANDING_ID_ENUM } from '../../epic/home-header/constant';
import styled from 'styled-components';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { LandingMentalTraumaAboutContainer } from '../../epic/landing-mentalTrauma-about';
import { LandingAdvantageContainer } from '../../epic/landing-advantage';
import { LandingDepressionHeroContainer } from '../../epic/landing-depression-hero';
import { KnowledgeLandingVideoContainer } from '../../epic/landing-video';
import { ThemesOfTherapy } from '../../epic/themes-of-therapy';
import { TITLE } from '../../epic/themes-of-therapy/constant';
import { LANDING_THEME_ID } from './constant';
import { LandingMentalTraumaHeroContainer } from '../../epic/landing-mentalTrauma-hero';
import { LandingSelfEsteemHeroContainer } from '../../epic/landing-selfEsteem-hero';
import { LandingDepressionAboutContainer } from '../../epic/landing-depression-about';
import { LandingSelfEsteemAboutContainer } from '../../epic/landing-selfEsteem-about';
import { HomeHeaderLinkContainer } from '../../epic/home-header-second';

const TITLES: Record<string, string> = {
  [LANDING_THEME_ID.DEPRESSION]: 'Сяйво - Депресія',
  [LANDING_THEME_ID.SELF_ESTEEM]: 'Сяйво - Самооцінка',
  [LANDING_THEME_ID.MENTAL_TRAUMA]: 'Сяйво - Психологічна травма',
};

export const Page: React.FC<RouteComponentProps<{ id: string }>> = ({
  match,
}) => {
  const id = String(match.params.id);

  useEffect(() => {
    const title = TITLES[id] || 'Сяйво - Психологічна платформа для всієї родини';
    document.title = title;
  }, [id]);
  console.log(id);
  return (
    <PageElem background="active">
      <LayoutAppElem oneColumn>
        <SectionHome id={LANDING_ID_ENUM.HOME}>
          <HomeHeaderLinkContainer />
        </SectionHome>

        <SectionHome>
          {id === LANDING_THEME_ID.DEPRESSION && (
            <LandingDepressionHeroContainer />
          )}
          {id === LANDING_THEME_ID.SELF_ESTEEM && (
            <LandingSelfEsteemHeroContainer />
          )}
          {id === LANDING_THEME_ID.MENTAL_TRAUMA && (
            <LandingMentalTraumaHeroContainer />
          )}
        </SectionHome>

        <SectionVideo>
          <KnowledgeLandingVideoContainer id={id} />
        </SectionVideo>

        <SectionAbout>
          {id === LANDING_THEME_ID.DEPRESSION && (
            <LandingDepressionAboutContainer />
          )}
          {id === LANDING_THEME_ID.SELF_ESTEEM && (
            <LandingSelfEsteemAboutContainer />
          )}
          {id === LANDING_THEME_ID.MENTAL_TRAUMA && (
            <LandingMentalTraumaAboutContainer />
          )}
        </SectionAbout>
        <SectionService>
          <LandingAdvantageContainer />
        </SectionService>
        <SectionService>
          <ThemesOfTherapy title={TITLE.KNOWLEDGE_LANDING} />
        </SectionService>

        <SectionFooter>
          <HomeFooterContainer />
        </SectionFooter>
      </LayoutAppElem>
    </PageElem>
  );
};

const SectionHome = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
`;

const SectionService = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  div {
    background-image: none !important;
  }
`;

const SectionVideo = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionAbout = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const SectionFooter = styled.section`
  background-color: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;
