import React, { useRef, useState } from 'react';
import { I18nContext, Trans } from 'react-i18next';
import styled from 'styled-components';

import { TextElem } from '../../common/text';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';

import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { i18n } from '../../lib/lang';
import { LANDING_THEME_ID } from '../../page/knowledge-landing/constant';

export const Component: React.FC<{ id: string }> = ({ id }) => {
  // const data = {
  //   video: {
  //     url: i18n.t('KNOWLEDGE_LANDING.VIDEO.URL'),
  //   },
  // };

  const poster: Record<string, string> = {
    [LANDING_THEME_ID.DEPRESSION]: i18n.t('KNOWLEDGE_LANDING.VIDEO.DEPRESSION'),
    // 1: './assets/img/knowledge/video_poster_depression.jpg',
    [LANDING_THEME_ID.MENTAL_TRAUMA]: i18n.t(
      'KNOWLEDGE_LANDING.VIDEO.MENTAL_TRAUMA',
    ),
    [LANDING_THEME_ID.SELF_ESTEEM]: i18n.t(
      'KNOWLEDGE_LANDING.VIDEO.SELF_ESTEEM',
    ),
  };

  const video: Record<string, string> = {
    [LANDING_THEME_ID.SELF_ESTEEM]:
      'https://bucketeer-a2d88b6d-f8a7-4710-a7e9-a7fff206a695.s3.eu-west-1.amazonaws.com/G8g-self_esteem.MP4',
    [LANDING_THEME_ID.DEPRESSION]:
      'https://bucketeer-a2d88b6d-f8a7-4710-a7e9-a7fff206a695.s3.eu-west-1.amazonaws.com/irV-depression.MP4',
    [LANDING_THEME_ID.MENTAL_TRAUMA]:
      'https://bucketeer-a2d88b6d-f8a7-4710-a7e9-a7fff206a695.s3.eu-west-1.amazonaws.com/zkY-mental_trauma.MP4',
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.play(); // Відтворення відео
      setIsPlaying(true); // Приховуємо кнопку і постер
    }
  };
  return (
    <Wrapper>
      <HeroContainer>
        <HeroBlock>
          <TextElem color="textPrimary" size="semiSmall" type="medium">
            {i18n.t('KNOWLEDGE_LANDING.VIDEO.VIDEO_TEXT_BOLD')}
            <TextElem
              color="textPrimary"
              size="semiSmall"
              type="light"
              tid="KNOWLEDGE_LANDING.VIDEO.VIDEO_TEXT"
              lineHeight="22.4px" //по дизайну
            />
          </TextElem>
        </HeroBlock>
        <VideoContainer>
          <VideoPreviewContainer>
            <VideoWrapper>
              {!isPlaying && <PlayButton onClick={handlePlay} />}
              <StyledVideo
                poster={poster[id]}
                ref={videoRef}
                src={video[id]}
                controls={isPlaying}
                preload="metadata"
              >
                {/* <source src={video[id]} type="video/mp4" />
                Ваш браузер не підтримує відео.{' '}
                <img src={poster[id]} alt="Fallback image" /> */}
              </StyledVideo>
            </VideoWrapper>
          </VideoPreviewContainer>

          {/* <VideoPreviewContainer>
          {data?.video?.url && (
            <VideoStyled controls style={{ width: '100%', height: 'auto' }}>
              <source src={data?.video?.url} type="video/mp4" />
              Your browser does not support the video tag.
            </VideoStyled>
          )}
          {!data?.video?.url && (
            <TextElemName
              type="medium"
              color="textPrimary"
              size="main"
              tid="USER.PSYCHO.PROFILE.NOT_SET"
            />
          )}
        </VideoPreviewContainer> */}
        </VideoContainer>
      </HeroContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${Spacing(350)};
  padding: 0 ${Spacing(4)};
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  overflow: hidden;
  // aspect-ratio: 8 / 4.5;
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 18px;
    border-color: transparent transparent transparent black;
    margin-left: 5px;
  }
`;

const StyledVideo = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
`;

const VideoPreviewContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 270px;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;

  // height: 300px; /* Встановіть потрібну висоту для прев'ю */
`;

const PosterImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const VideoContainer = styled.div`
  width: 100%;
  padding: ${Spacing(4.5)} 0;
  @media (max-width: 1024px) {
    order: 1;
    padding: 0;
  }
`;

const VideoStyled = styled.video`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[
    SIZE_BORDER_RADIUS_ENUM.ALERT
  ]}px !important;
  width: 100%;
  height: 100%;
  max-height: ${Spacing(67.5)};
  cursor: pointer;
`;

// const VideoPreviewContainer = styled.div`
//   height: 100%;
//   width: 100%;
//   min-height: 137px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const VideoWrapper = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
//   border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.ALERT]}px;
//   overflow: hidden;
// `;

// const PlayButton = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 60px;
//   height: 60px;
//   background-color: rgba(255, 255, 255, 0.8);
//   border-radius: 50%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   z-index: 2;

//   &:hover {
//     background-color: rgba(255, 255, 255, 1);
//   }

//   &::before {
//     content: '';
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-width: 10px 0 10px 18px;
//     border-color: transparent transparent transparent black;
//     margin-left: 5px;
//   }
// `;

const HeroContainer = styled.div`
  font-family: 'e-UkraineHead' !important;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  width: 100%;
  max-width: ${Spacing(344)};
  display: grid;
  grid-template-columns: max-content 1fr;
  padding: ${Spacing(12)} ${Spacing(30)};
  gap: ${Spacing(18)};

  @media (max-width: 1325px) {
    // padding-right: clamp(0px, 6vw, 120px);
    // padding-left: clamp(0px, 6vw, 120px);
    padding-right: clamp(0px, 5.1vw, 120px);
    padding-left: clamp(0px, 5.1vw, 120px);
  }

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: ${Spacing(4)};
    padding: ${Spacing(5)} 0 ${Spacing(4)};
  }
`;

const HeroBlock = styled.div`
  width: 100%;
  max-width: ${Spacing(86)};

  span {
    white-space: pre-line;
    display: contents;
  }

  // display: grid;
  // gap: ${Spacing(8)};
  padding: ${Spacing(8)} 0;

  @media (max-width: 1024px) {
    order: 2;
    padding: 0;
    max-width: 100%;
  }
`;

const HeroText = styled.div`
  display: grid;
  min-width: ${Spacing(120)};
  gap: ${Spacing(4)};
  @media (max-width: 768px) {
    min-width: ${Spacing(75)};
  }
`;

const HeroTitle = styled(TextElem)`
  font-family: 'Oks-Free' !important;
  line-height: 1.5em;import { type } from './../../theme/value';

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  @media (max-width: 768px) {
    font-size: ${Spacing(7)};
  }
`;

const HeroTitleName = styled(HeroTitle)`
  text-decoration: underline;
`;

const HeroSubtitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_SECONDARY]};
  line-height: 160%;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.LIGHT]};

  @media (max-width: 768px) {
    font-size: ${Spacing(4.5)};
  }
`;

const HeroImg = styled.img`
  max-width: 90%;

  @media (max-width: 992px) {
    max-height: ${Spacing(100)};
    width: 100%;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled(ButtonElem)``;
