import React from 'react';
import styled from 'styled-components';
import { TextElem } from '../../common/text';
import { ButtonElem } from '../../common/button';
import { COLOR_DATA, COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PaddingContainer } from '../home-footer/component';
import { Spacing } from '../../theme';
import { i18n } from '../../lib/lang';
import { scrollToSection } from '../../lib/util/scrollToSection';
import { LANDING_ID_ENUM } from '../home-header/constant';
import { SUBTITLE_LIST } from './constant';

export const Component: React.FC<{}> = () => {
  return (
    <BodyContainer>
      <AboutContainer>
        <AboutBlock>
          <AboutText>
            <AboutHeadTitle fontFamily="Oks-Free">
              <HeadTitle>
                <HeadTitlePrimary>
                  {i18n.t('LANDING.HOME_ABOUT.HEAD_TITLE.TITLE_1')}
                </HeadTitlePrimary>
                <br />
                <HeadTitleHighlight>
                  {i18n.t('LANDING.HOME_ABOUT.HEAD_TITLE.TITLE_2')}
                </HeadTitleHighlight>
                <HeadTitlePrimary>
                  {i18n.t('LANDING.HOME_ABOUT.HEAD_TITLE.TITLE_3')}
                </HeadTitlePrimary>
              </HeadTitle>
            </AboutHeadTitle>
            <AboutTitle size="main">
              {i18n.t('LANDING.HOME_ABOUT.TITLE')}
            </AboutTitle>
            <ul>
              {SUBTITLE_LIST.map((e) => (
                <li>
                  <SubtitleText
                    lineHeight="1.6em"
                    color="textSecondary"
                    tid={e}
                    size="semiSmall"
                  />
                </li>
              ))}
            </ul>
            <SubtitleText
              lineHeight="1.6em"
              tid="LANDING.HOME_ABOUT.WISH"
              size="semiSmall"
            />
          </AboutText>
          <StyledButton
            onClick={() => scrollToSection(LANDING_ID_ENUM.SERVICES)}
            type="landing"
          >
            {i18n.t('LANDING.HOME_ABOUT.BUTTON')}
          </StyledButton>
        </AboutBlock>
      </AboutContainer>
      <AboutImg
        src="./assets/img/landing/about_picture.jpg"
        alt="Pair on the beach"
      />
    </BodyContainer>
  );
};
const BodyContainer = styled(PaddingContainer)`
  background: ${COLOR_DATA[COLOR_ENUM.BACKGROUND_PRIMARY]};
  gap: ${Spacing(2.5)};

  @media screen and (max-width: 768px) {
    padding: ${Spacing(8)} ${Spacing(4)};
  }
`;

const AboutContainer = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: ${COLOR_DATA[COLOR_ENUM.WHITE]};
  padding: ${Spacing(25)} ${Spacing(25)} ${Spacing(12.75)} ${Spacing(25)};
  margin: 0 auto;
  min-height: ${Spacing(150)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  width: 100%;
  max-width: ${Spacing(350)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px ${Spacing(3.75)} ${Spacing(10)} 0
    ${COLOR_DATA[COLOR_ENUM.BOX_SHADOW_COLOR]};

  @media (max-width: 1324px) {
    padding: ${Spacing(12.5)};
  }

  @media (max-width: 768px) {
    padding: ${Spacing(7.5)};
  }

  @media (max-width: 480px) {
    padding: ${Spacing(5)};
  }
`;

const AboutBlock = styled.div`
  display: grid;
  gap: ${Spacing(8)};

  @media (max-width: 1324px) {
    margin: 0 auto;
    max-width: 100%;
    max-height: none;
  }
`;

const AboutText = styled.div`
  display: grid;
  gap: ${Spacing(6)};
`;

const AboutHeadTitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};

  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.LARGE]};
  }

  @media (max-width: 480px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MEDIUM]};
  }
`;

const HeadTitle = styled.p`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN_HEAD_TITLE]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  text-align: left;
  margin: 0px;
`;

const HeadTitlePrimary = styled.span`
  font-family: Oks-Free !important;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN_HEAD_TITLE]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
`;

const HeadTitleHighlight = styled.span`
  flex-grow: 0;
  flex-shrink: 0;
  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MAIN_HEAD_TITLE]}px;
  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};
  text-align: left;
  color: ${COLOR_DATA[COLOR_ENUM.BUTTON_PRIMARY_SECOND]};
  text-decoration: underline;
`;

const AboutTitle = styled(TextElem)`
  color: ${COLOR_DATA[COLOR_ENUM.TEXT_PRIMARY]};
  line-height: 160%;

  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]};
  }

  @media (max-width: 480px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SMALL]};
  }
`;

const SubtitleText = styled(TextElem)`
  @media (max-width: 768px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.SEMI_SMALL]};
  }

  @media (max-width: 480px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]};
  }
`;

const AboutImg = styled.img`
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MENU]}px;
  max-height: 100%;
  max-width: ${Spacing(142.5)};

  @media (max-width: 1324px) {
    max-height: 100%;
    max-width: ${Spacing(135)};
  }

  @media (max-width: 1050px) {
    display: none;
  }
`;

const StyledButton = styled(ButtonElem)``;
