import React, { useState } from 'react';

import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';

import { GridElem } from '../../common/grid';

import { Spacing } from '../../theme';
import {
  PAYMENT_ITEM_DATA_INTER,
  PAYMENT_ITEM_LIST_DATA_INTER,
} from '../../data/payment/constant';
import { AlertEmptyElem } from '../../common/alert-empty';

import { COLOR_ENUM } from '../../theme/color';
import { TextElem } from '../../common/text';
import { SkeletonListElem } from '../../common/skeleton-list';
import { PaymentItemElem } from '../../data/payment/frame/payment-item-list.elem';
import { IonInfiniteScroll } from '@ionic/react';
import { PaymentSkeletonElem } from '../../data/payment/frame/payment-skeleton.elem';

export const Component: React.FC<{
  data?: PAYMENT_ITEM_LIST_DATA_INTER;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  isFetching?: boolean;
  fetchNextPage: Function;
  isIdle?: boolean;
}> = ({
  data,
  isSuccess,
  isError,
  errorMessage,
  isFetching,
  fetchNextPage,
  isIdle,
  isLoading,
}) => {
  console.log('isIdle', isIdle);
  return (
    <>
      <GridElem spacing={5}>
        {isError && <AlertActionElem text={errorMessage} />}
        {data && isSuccess && data.isEmpty && <AlertEmptyElem />}
        <Container>
          <GridStyled>
            {data && !data.isEmpty && (
              <>
                <Head>
                  <TextElem
                    tid="PAYMENT.LIST.HEAD.ID"
                    color="textSecondary"
                    size="semiSmall"
                  />
                  <TextElem
                    tid="PAYMENT.LIST.HEAD.DATE"
                    color="textSecondary"
                    size="semiSmall"
                  />

                  <TextElem
                    tid="PAYMENT.LIST.HEAD.AMOUNT"
                    color="textSecondary"
                    size="semiSmall"
                  />

                  <TextElem
                    tid="PAYMENT.LIST.HEAD.CLIENT"
                    color="textSecondary"
                    size="semiSmall"
                  />
                  <TextElem
                    tid="PAYMENT.LIST.HEAD.PSYCHOLOGIST"
                    color="textSecondary"
                    size="semiSmall"
                  />
                </Head>
                {isSuccess &&
                  data?.list.map((item: PAYMENT_ITEM_DATA_INTER) => (
                    <PaymentItemElem {...item} />
                  ))}
              </>
            )}
          </GridStyled>
          {isLoading && !data?.isEmpty && (
            <SkeletonWrapper>
              <SkeletonListElem />
            </SkeletonWrapper>
          )}
          <SkeletonWrapper>
            <SkeletonListElem />
          </SkeletonWrapper>

          {data &&
            data.list &&
            data.list.length > 39 &&
            !data.isEmpty &&
            !isFetching && (
              <IonInfiniteScroll
                onIonInfinite={async (ev) => {
                  await fetchNextPage();
                  setTimeout(() => ev.target.complete(), 500);
                }}
                threshold="100px"
              >
                <div style={{ height: '1px' }}></div>
              </IonInfiniteScroll>
            )}
        </Container>
      </GridElem>
    </>
  );
};

const SkeletonWrapper = styled.div`
  margin-top: ${Spacing(4)};
`;

const Container = styled.div`
  /* overflow-x: auto; */
  scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
    transparent;
  scrollbar-width: thin;
  width: 100%;
`;

const GridStyled = styled(GridElem)`
  grid-auto-columns: repeat(3, 'auto');
  row-gap: ${Spacing(3)};
  /* width: 1050px; */
`;

const Head = styled(GridElem)`
  max-height: 56px;
  padding: 19px 20px;
  width: 100%;
  align-items: center;
  display: grid;
  grid-column: span 5;
  grid-template-columns: subgrid;
  gap: ${Spacing(12)};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  @media screen and (max-width: 1160px) {
    display: none;
  }
`;
