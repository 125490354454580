import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';
import { Spacing } from '../../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_LAYOUT_DATA,
  SIZE_LAYOUT_ENUM,
} from '../../../theme/size';
import { useHistory } from 'react-router';
import { COLOR_ENUM } from '../../../theme/color';
import ArrowLeftSvg from '../../../asset/svg/button/arrow-left.svg';
import {
  VALUE_OPACITY_DATA,
  VALUE_OPACITY_ENUM,
  VALUE_TRANSITION_DATA,
  VALUE_TRANSITION_ENUM,
} from '../../../theme/value';
import { PSYCHO_LIST_PAGE_PATH } from '../../../page/psycho-list';

export const HeaderProfile: React.FC = () => {
  const history = useHistory();

  const handleAction = (path?: string | boolean) => {
    if (typeof path === 'string') {
      history.push(`${path}`);
    } else if (path === true) {
      if (history.length > 1) {
        history.go(-1);
      } else {
        history.push(PSYCHO_LIST_PAGE_PATH);
      }
    }
  };

  return (
    <>
      {ReactDOM.createPortal(
        <HeaderContainer>
          <ButtonComponent onClick={() => handleAction(true)}>
            <img src={ArrowLeftSvg} alt="arrow" />
          </ButtonComponent>
        </HeaderContainer>,
        document.getElementById('layoutApp') || document.body,
      )}
    </>
  );
};

const ButtonComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  padding: ${Spacing(3)};
  transition: ${VALUE_TRANSITION_DATA[VALUE_TRANSITION_ENUM.HOVER]};
  cursor: pointer;

  :hover {
    opacity: ${VALUE_OPACITY_DATA[VALUE_OPACITY_ENUM.HOVER]};
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-self: end;
  margin: 0 auto;
  margin-top: ${Spacing(10)};
  padding: 0px 24px;
  align-items: center;
  max-width: calc(
    ${SIZE_LAYOUT_DATA[SIZE_LAYOUT_ENUM.DEFAULT]}px + ${Spacing(10 * 2)}
  );
`;
